const ProductCatalog = () => import('@components/Product/Catalog')
const ProductFilter = () => import('@components/Product/Filter')
const ProductOrder = () => import('@components/Product/Order')
const ProductSlider = () => import('@components/Product/Slider')
const ProductColors = () => import('@components/Product/Colors')
const ProductTextures = () => import('@components/Product/Textures')
const ProductQuestions = () => import('@components/Product/Questions')
const ProductReviews = () => import('@components/Product/Reviews')

export default function install(Vue) {
  Vue.component('ProductCatalog', ProductCatalog)
  Vue.component('ProductFilter', ProductFilter)
  Vue.component('ProductOrder', ProductOrder)
  Vue.component('ProductSlider', ProductSlider)
  Vue.component('ProductColors', ProductColors)
  Vue.component('ProductTextures', ProductTextures)
  Vue.component('ProductQuestions', ProductQuestions)
  Vue.component('ProductReviews', ProductReviews)
}
