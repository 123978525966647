import * as LINKS from './consts'

const IdeasComponent = () => import('@components/Profile/Designer/Ideas')
const IdeaComponent = () => import('@components/Profile/Designer/Idea')
const AchievementsComponent = () => import('@components/Profile/Designer/Achievements')
const RequestsComponent = () => import('@components/Profile/Designer/Requests')

export const routes = [
  {
    path: LINKS.PROFILE_IDEAS,
    component: IdeasComponent,
    name: 'ideas',
    meta: { permissions: ['ideas'] },
  },
  {
    path: LINKS.PROFILE_IDEA_CREATE,
    component: IdeaComponent,
    name: 'ideaCreate',
    meta: { permissions: ['ideas'] },
  },
  {
    path: LINKS.PROFILE_IDEA,
    component: IdeaComponent,
    props: true,
    name: 'idea',
    meta: { permissions: ['ideas'] },
  },
  {
    path: LINKS.PROFILE_ACHIVEMENTS,
    component: AchievementsComponent,
    name: 'achievements',
    meta: { permissions: ['achievements'] },
  },
  {
    path: LINKS.PROFILE_REQUESTS,
    component: RequestsComponent,
    name: 'contactRequests',
    meta: { permissions: ['contactRequests'] },
  },
]
