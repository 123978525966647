/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val) {
  // if (this.getCookie(name) ) return

  const bakeCookie = () => {
    const cookie = [name, '=', JSON.stringify(val), '; path=/;'].join('')
    document.cookie = cookie
  }
  bakeCookie()
}
