import { sessionRetrieve } from '@services/comparison.service'

export const catalog = {
  namespaced: true,
  state: {
    filterCount: 0,
    sessionInfo: {
      comparison: {
        categories: [],
        options: [],
        sections: [],
      },
    },
  },
  actions: {
    async getSessionInfo({ commit }) {
      const { data } = await sessionRetrieve.execute()
      const { item } = data
      commit('CHANGE_SESSION_INFO', item)
    },
  },
  mutations: {
    CHANGE_FILTER_COUNT(state, result) {
      state.filterCount = result
    },
    CHANGE_SESSION_INFO(state, result) {
      state.sessionInfo = result
    },
  },
}
