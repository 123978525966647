import * as LINKS from '@router/Center/consts'

const Buildings = () => import('@components/Center/Buildings')
const Floor = () => import('@components/Center/Floor')

export const routes = [
  {
    path: LINKS.MAP_BUILDINGS,
    component: Buildings,
    name: 'buildings',
    meta: { roles: ['designer', 'shop'] },
  },
  {
    path: LINKS.MAP_FLOOR,
    component: Floor,
    props: true,
    name: 'floor',
    meta: { roles: ['designer', 'shop'] },
  },
]
