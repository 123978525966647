<template lang="pug">
  include /mixins.pug
  +b.LABEL.d-control-input__label(
    :for='id',
    v-if="inputLabel"
  )
    +b.g-row.--align_center.--space_xs
      +b.g-cell
        span {{ inputLabel }}
      +b.g-cell(v-if="hint")
        +b.hint(v-tooltip.auto="{content: hint, trigger: 'click hover'}")
          +e.icon
            +b.I.icon.--type_hint
      +b.g-cell(v-if='isRequired')
        +b.SPAN.ds-caption.--color_main &nbsp;*
      +b.g-cell(v-if='description')
        +b.SPAN.ds-caption.--size_4xs {{ description }}
</template>

<script>
export default {
  name: 'StaticLabel',
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    description: {},
  },
}
</script>
