import { routes as ShopCollection } from '@router/Profile/Shop/Collection/routes'
import { routes as ShopCommon } from '@router/Profile/Shop/Common/routes'
import { routes as ShopProduct } from '@router/Profile/Shop/Product/routes'
import { routes as ShopService } from '@router/Profile/Shop/Service/routes'
import { routes as ShopVacancy } from '@router/Profile/Shop/Vacancy/routes'
import { routes as ShopTemplate } from '@router/Profile/Shop/Template/routes'
import { routes as ShopImport } from '@router/Profile/Shop/Import/routes'
import { routes as ShopBrand } from '@router/Profile/Shop/Brand/routes'

export const routes = [
  ...ShopCollection,
  ...ShopCommon,
  ...ShopProduct,
  ...ShopService,
  ...ShopVacancy,
  ...ShopTemplate,
  ...ShopImport,
  ...ShopBrand,
]
