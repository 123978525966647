import * as LINKS from './consts'

const ViewComponent = () => import('@components/Profile/Common/View')
const ArticlesComponent = () => import('@components/Profile/Common/Articles')
const ArticleComponent = () => import('@components/Profile/Common/Article')

export const routes = [
  {
    path: LINKS.PROFILE,
    component: ViewComponent,
    name: 'profileSettings',
    meta: { permissions: ['profileSettings', 'shopSettings'] },
  },
  {
    path: LINKS.PROFILE,
    component: ViewComponent,
    name: 'shopSettings',
    meta: { permissions: ['shopSettings'] },
  },
  {
    path: LINKS.PROFILE_ARTICLES,
    component: ArticlesComponent,
    name: 'posts',
    meta: { permissions: ['posts'] },
  },
  {
    path: LINKS.PROFILE_ARTICLE_CREATE,
    component: ArticleComponent,
    name: 'articleCreate',
    meta: { permissions: ['posts'] },
  },
  {
    path: LINKS.PROFILE_ARTICLE,
    component: ArticleComponent,
    props: true,
    name: 'article',
    meta: { permissions: ['posts'] },
  },
]
