import Vue from 'vue'

Vue.directive('phones', {
  bind: node => {
    const items = Array.from(node.querySelectorAll('.js-phone-item'))
    const button = node.querySelector('.js-phone-trigger')
    items.forEach(el => {
      const href = el.getAttribute('data-link')
      const replaced = href.replace(/\^d/g, '').slice(-10)
      let prepared = replaced.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1)$2-$3-$4')
      if (button) {
        const firstSymbols = prepared.slice(0, 5)
        const lastSymbols = prepared.slice(5)
        prepared = `${firstSymbols}${lastSymbols.replace(/\d/g, 'X')}`
      } else {
        el.setAttribute('href', `tel:+38${replaced}`)
      }
      el.innerText = `+38 ${prepared}`
    })
    if (button) {
      button.onclick = () => {
        items.forEach(el => {
          const href = el.getAttribute('data-link')
          const replaced = href.replace(/\^d/g, '').slice(-10)
          const prepared = replaced.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1)$2-$3-$4')
          el.classList.add('is-interactive')
          el.setAttribute('href', `tel:+38${replaced}`)
          el.innerText = `+38 ${prepared}`
        })
        button.style.display = 'none'
        // eslint-disable-next-line
        gtag('event', 'click_pokaz_nomer', {'event_category': 'button'})
      }
    }
  },
})
