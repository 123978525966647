const Link = () => import('@components/Compare/Link')
const Button = () => import('@components/Compare/Button')
const View = () => import('@components/Compare/View')
const Product = () => import('@components/Compare/Product')
const ProductMobile = () => import('@components/Compare/ProductMobile')

export default function install(Vue) {
  Vue.component('CompareLink', Link)
  Vue.component('CompareButton', Button)
  Vue.component('CompareView', View)
  Vue.component('CompareProduct', Product)
  Vue.component('CompareProductMobile', ProductMobile)
}
