import VueRouter from 'vue-router'
import Vue from 'vue'

import { routes as CenterRoutes } from '@router/Center/routes'
import { routes as ProfileDesignerRoutes } from '@router/Profile/Designer/routes'
import { routes as ProfileShopRoutes } from '@router/Profile/Shop/routes'
import { routes as ProfileCommonRoutes } from '@router/Profile/Common/routes'

Vue.use(VueRouter)

const scrollBehavior = () => {
  const timeout = 500
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ x: 0, y: 0 })
      reject()
    }, timeout)
  })
}

export const checkPermissions = router => {
  router.beforeEach((to, from, next) => {
    const timeout = 100
    setTimeout(() => {
      const { isProfile, userRole } = window
      const parsedUserRole = JSON.parse(userRole)
      const userPermissions = parsedUserRole.permissions
      const [firstPermission] = userPermissions
      const { meta: { permissions = [] } } = to
      const isAccessed = userPermissions.some(perm => permissions.includes(perm))
      if (isProfile) {
        if (isAccessed) {
          next()
        } else {
          next({ name: firstPermission })
        }
      } else {
        next()
      }
    }, timeout)
  })
}

export const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes: [
    ...CenterRoutes,
    ...ProfileDesignerRoutes,
    ...ProfileShopRoutes,
    ...ProfileCommonRoutes,
  ],
})
