import Vue from 'vue'

Vue.filter('formatDate', value => {
  const date = value.split('T')[0]
    .split('-')
    .reverse()
    .join('.')
  return date
})

/* eslint-disable */
export const formatDateTime = value => {
  const splitedDate = value.split('T')
  const date = splitedDate[0]
    .split('-')
    .reverse()
    .join('.')
  const time = splitedDate[1].split('.')[0]
  return `${date} ${time}`
}

Vue.filter('formatDateTime', value => {
  return formatDateTime(value)
})

export const formatPhone = value => {
  const replaced = value.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2 $3 $4')
  return `+38 ${replaced}`
}

Vue.filter('formatPhone', value => {
  return formatPhone(value)
})
