import { extend } from 'vee-validate'
import { app } from '@app/initApp'
import * as rules from 'vee-validate/dist/rules'
/* eslint-disable-next-line */
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

extend('arrayLength', {
  validate(value, args) {
    return value.length <= args
  },
  message(value, sec) {
    return `${app._('Максимально допустимое количество -')} ${sec[0]}`
  },
})

extend('arrayLengthMin', {
  validate(value, args) {
    const isNotDeletedItems = value.reduce((acc, el) => {
      /* eslint-disable-next-line */
      if (el && !el._delete) acc++
      return acc
    }, 0)
    return isNotDeletedItems >= args[0]
  },
  message(value, sec) {
    return `${app._('Минимальное количество -')} ${sec[0]}`
  },
})

extend('emailMethod', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value)
  },
  message() {
    return app._('Поле должно быть действительным электронным адресом.')
  },
})

extend('username', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ `'’-]+$/i.test(value)
  },
  message() {
    return app._('Поле имеет ошибочный формат.')
  },
})

extend('phoneNumber', {
  validate(value) {
    const phoneLength = 12
    return phoneLength === value.replace(/\D/g, '').toString().length
  },
  message() {
    return app._('Введите полный номер телефона.')
  },
})

const passwordValidation = value => {
  if (/^[a-zA-Z\',-\.\:\@\*_!|\^?\'~{}\+\&\;\"\<\\\>`+\(\)\%$#№[\]=0-9/]+$/i.test(value)) {
    if (/[',-\.\:\@\*_!|\^?\'~{}\+\&\;\"\<\\\>`+\(\)\%$#№[\]=]/.test(value) &&
      /[A-Z]/.test(value) &&
      /[a-z]/.test(value)) {
      return true
    }
  }
  return false
}

extend('numeric_method', {
  validate(value) {
    return /^[0-9.]*$/i.test(value)
  },
  message() {
    return app._('Поле должно быть числом.')
  },
})

extend('digitMethod', {
  validate(value) {
    return /^[0-9]*$/i.test(value)
  },
  message() {
    return app._('Поле должно быть целым числом.')
  },
})

extend('passwordMethod', {
  validate(value) {
    return passwordValidation(value)
  },
  message() {
    return app._('Введите более сложный пароль.')
  },
})

extend('alpha', {
  ...rules.alpha,
  message() {
    return app._('Поле может содержать только буквы.')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('Поле должно быть числом.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('Это поле обязательное.')
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Значения не совпадают.')
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return `${app._('Количество символов должно быть не меньше')} ${sec.length}`
  },
})

extend('max', {
  ...rules.max,
  message(value, sec) {
    return `${app._('Количество символов не должно превышать')} ${sec.length}`
  },
})

extend('max_value', {
  ...rules.max_value,
  message(value, sec) {
    return `${app._('Число не должно быть больше')} ${sec.max}`
  },
})
