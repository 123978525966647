import * as LINKS from './consts'

const VacancyCatalog = () => import('@components/Profile/Shop/Vacancy/Catalog')
const VacancyResumeView = () => import('@components/Profile/Shop/Vacancy/Resume')
const VacancyView = () => import('@components/Profile/Shop/Vacancy/View')

export const routes = [
  {
    path: LINKS.PROFILE_VACANCIES,
    component: VacancyCatalog,
    name: 'shopVacancies',
    meta: { permissions: ['vacancies'] },
  },
  {
    path: LINKS.PROFILE_VACANCY,
    component: VacancyResumeView,
    props: true,
    name: 'shopVacancyResume',
    meta: { permissions: ['vacancies'] },
  },
  {
    path: LINKS.PROFILE_VACANCY_UPDATE,
    component: VacancyView,
    name: 'VacancyView',
    props: true,
    meta: { permissions: ['vacancies'] },
  },
  {
    path: LINKS.PROFILE_VACANCY_CREATE,
    component: VacancyView,
    name: 'VacancyCreate',
    meta: { permissions: ['vacancies'] },
  },
]
