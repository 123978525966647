import Vuex from 'vuex'
import Vue from 'vue'
import { profile } from './modules/profile'
import { catalog } from './modules/catalog'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      profile,
      catalog,
    },
  })
}
