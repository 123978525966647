/* eslint-disable */
import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import '@/js/directives'
import '@/js/filters'

import { install } from './install'

import { createApp } from './app'

import './styles/index.sass'

Vue.use(install)

// Vue.config.productionTip = false

const { app } = createApp({
  Vue, VueRouter, Vuex, I18n,
})

app.$mount('#app')

export { app }
