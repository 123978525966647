import CollectionElements from '@components/Profile/Shop/Collection'
import ProductElements from '@components/Profile/Shop/Product'
import SettingsElements from '@components/Profile/Shop/Settings'
import CommonElements from '@components/Profile/Shop/Common'
import TemplateElements from '@components/Profile/Shop/Template'
import ImportElements from '@components/Profile/Shop/Import'

export default function install(Vue) {
  Vue.use(CollectionElements)
  Vue.use(ProductElements)
  Vue.use(SettingsElements)
  Vue.use(CommonElements)
  Vue.use(TemplateElements)
  Vue.use(ImportElements)
}
