import Vue from 'vue'

Vue.directive('seo-text', {
  bind: node => {
    setTimeout(() => {
      const wrapper = node.querySelector('.js-seo-text-wrapper')
      const triggerMore = node.querySelector('.js-seo-text-more')

      if (!triggerMore || !wrapper) return

      if (wrapper.clientHeight > 130) {
        triggerMore.classList.add('is-visible')
        wrapper.classList.add('is-collapsed')
      }

      triggerMore.addEventListener('click', () => {
        wrapper.classList.add('is-active')
        triggerMore.classList.remove('is-visible')
        wrapper.classList.remove('is-collapsed')
      })
    }, 100)
  },
})
