import AuthElements from '@components/Auth'
import CommonElements from '@components/Common'
import ControlsElements from '@components/Controls'
import NavigationElements from '@components/Navigation'
import HomeElements from '@components/Home'
import ShopElements from '@components/Shop'
import DesignerElements from '@components/Designer'
import ArticleElements from '@components/Article'
import PromoElements from '@components/Promo'
import FormsElements from '@components/Forms'
import CenterElements from '@components/Center'
import ProfileElements from '@components/Profile'
import IdeaElements from '@components/Idea'
import ProductElements from '@components/Product'
import SearchElements from '@components/Search'
import CompareElements from '@components/Compare'

export function installComponents(Vue) {
  Vue.use(AuthElements)
  Vue.use(CommonElements)
  Vue.use(ControlsElements)
  Vue.use(NavigationElements)
  Vue.use(HomeElements)
  Vue.use(ShopElements)
  Vue.use(DesignerElements)
  Vue.use(ArticleElements)
  Vue.use(PromoElements)
  Vue.use(FormsElements)
  Vue.use(CenterElements)
  Vue.use(ProfileElements)
  Vue.use(IdeaElements)
  Vue.use(ProductElements)
  Vue.use(SearchElements)
  Vue.use(CompareElements)
}

export default { installComponents }
