const disableHover = () => {
  const { body } = document
  const timeout = 300
  let timer

  window.addEventListener('scroll', () => {
    clearTimeout(timer)

    if (!body.classList.contains('disable-hover')) {
      body.classList.add('disable-hover')
    }

    timer = setTimeout(() => {
      body.classList.remove('disable-hover')
    }, timeout)
  }, false)
}

export default disableHover
