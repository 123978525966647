import Vue from 'vue'
/* eslint-disable */

Vue.directive('timeline', node => {
  const slider = node.querySelector('.js-history-slider')
  if (slider) {
    const bullets = [...node.querySelectorAll('.js-bullet')]
    bullets[0].classList.add('is-active')
    bullets.forEach((el, index) => {
      el.onclick = () => {
        bullets.forEach(bullet => {
          bullet.classList.remove('is-active')
        })
        el.classList.add('is-active')
        const swiperEl = slider.swiper
        swiperEl.slideTo(index)
      }
    })
  }
})
