const ProfileAvatar = () => import('@components/Profile/Common/Avatar')
const ProfileController = () => import('@components/Profile/Common/Controller')
const ProfileNavbar = () => import('@components/Profile/Common/Navbar')
const CommonEmailChange = () => import('@components/Profile/Common/EmailChange')

export default function install(Vue) {
  Vue.component('ProfileController', ProfileController)
  Vue.component('ProfileNavbar', ProfileNavbar)
  Vue.component('ProfileAvatar', ProfileAvatar)
  Vue.component('CommonEmailChange', CommonEmailChange)
}
