/* eslint-disable */
import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import BoundLabel from './BoundLabel'
import ErrorComponent from './ErrorComponent'
import PrependComponent from './PrependComponent'
import AppendComponent from './AppendComponent'
import StaticLabel from './StaticLabel'
import UnifiedControlElement from './UnifiedControlDescriptor'
const Datepicker = () => import('./Datepicker')
const MultiSelect = () => import('./Multiselect')

const composerForInput = (input, label = BoundLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(UnifiedControlElement)

const composer = (input) => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)
// input with bound label
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)
// input with static input
const InputWithStaticLabel = composerForInput('Input', StaticLabel)
const ControlStaticInput = composer(InputWithStaticLabel)
// multiselect
const MultiselectWithLabel = composerForInput('MultiSelect')
const ControlMultiselect = composer(MultiselectWithLabel)
// multiselect
const DatepickerWithLabel = composerForInput('Datepicker')
const ControlDatepicker = composer(DatepickerWithLabel)
// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component('Datepicker', Datepicker)
  Vue.component('MultiSelect', MultiSelect)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
  Vue.component('PrependComponent', PrependComponent)
  Vue.component('AppendComponent', AppendComponent)
  Vue.component('InputWithLabel', InputWithLabel)
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}Datepicker`, ControlDatepicker)
}
