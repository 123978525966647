import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSlickCarousel from 'vue-slick-carousel'
import VueBem from '@aspectus/vue-bem'
import { installComponents } from '@components'
import ReadMore from 'vue-read-more'

import VueSlider from 'vue-slider-component'
import StarRating from 'vue-star-rating'
import { VTooltip } from 'v-tooltip'
import Viewer from 'v-viewer'
import Notifications from 'vue-notification'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(Viewer)
  Vue.use(VueAwesomeSwiper)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(installComponents)

  const windowWidth = window.innerWidth
  const xl = 1200
  let transition = ''
  if (windowWidth >= xl) {
    transition = 'nice-modal-fade'
  }
  Vue.use(VModal, {
    dynamic: true,
    dynamicDefaults: {
      transition,
      height: 'auto',
      clickToClose: true,
      scrollable: true,
    },
  })

  Vue.use(ReadMore)
  Vue.use(Notifications)

  Vue.component('VueSlider', VueSlider)

  Vue.component('star-rating', StarRating)
  Vue.component('vue-slick-carousel', VueSlickCarousel)
  Vue.directive('tooltip', VTooltip)
}

export default { install }
