import * as LINKS from './consts'

const TemplateCatalog = () => import('@components/Profile/Shop/Template/Catalog')
const TemplateView = () => import('@components/Profile/Shop/Template/View')

export const routes = [
  {
    path: LINKS.PROFILE_TEMPLATES,
    component: TemplateCatalog,
    name: 'productTemplates',
    meta: { permissions: ['productTemplates'] },
  },
  {
    path: LINKS.PROFILE_TEMPLATE_COMMON,
    component: TemplateView,
    name: 'templateView',
    props: true,
    meta: { permissions: ['productTemplates'] },
  },
  {
    path: LINKS.PROFILE_TEMPLATE_CREATE,
    component: TemplateView,
    name: 'templateCreate',
    meta: { permissions: ['productTemplates'] },
  },
]
