import * as LINKS from './consts'

const BrandRequests = () => import('@components/Profile/Shop/Brand/Requests')

export const routes = [
  {
    path: LINKS.PROFILE_BRAND_REQUEST,
    component: BrandRequests,
    name: 'brands',
    meta: { permissions: ['brands'] },
  },
]
