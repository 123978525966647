import * as LINKS from './consts'

const ShopOrders = () => import('@components/Profile/Shop/Common/Orders')
const ShopQuestions = () => import('@components/Profile/Shop/Common/Questions')
const ShopManagers = () => import('@components/Profile/Shop/Common/Managers')
const ShopNotifications = () => import('@components/Profile/Shop/Common/Notifications')

export const routes = [
  {
    path: LINKS.PROFILE_SHOP_QUESTIONS,
    component: ShopQuestions,
    name: 'shopQuestions',
    meta: { permissions: ['shopQuestions'] },
  },
  {
    path: LINKS.PROFILE_SHOP_ORDERS,
    component: ShopOrders,
    name: 'shopOrders',
    meta: { permissions: ['orders'] },
  },
  {
    path: LINKS.PROFILE_SHOP_MANAGERS,
    component: ShopManagers,
    name: 'managers',
    meta: { permissions: ['managers'] },
  },
  {
    path: LINKS.PROFILE_SHOP_NOTIFICATIONS,
    component: ShopNotifications,
    name: 'notifications',
    meta: { permissions: ['notifications'] },
  },
]
