import * as LINKS from './consts'

const ServiceCatalog = () => import('@components/Profile/Shop/Service/Catalog')
const ServiceRequests = () => import('@components/Profile/Shop/Service/Requests')
const ServiceView = () => import('@components/Profile/Shop/Service/View')

export const routes = [
  {
    path: LINKS.PROFILE_SERVICE_REQUEST,
    component: ServiceRequests,
    name: 'shopServiceRequest',
    meta: { permissions: ['serviceRequest'] },
  },
  {
    path: LINKS.PROFILE_SHOP_SERVICES,
    component: ServiceCatalog,
    name: 'services',
    meta: { permissions: ['services'] },
  },
  {
    path: LINKS.PROFILE_SHOP_SERVICE_VIEW,
    component: ServiceView,
    name: 'shopServiceView',
    props: true,
    meta: { permissions: ['services'] },
  },
  {
    path: LINKS.PROFILE_SHOP_SERVICE_CREATE,
    component: ServiceView,
    name: 'shopServiceCreate',
    meta: { permissions: ['services'] },
  },
]
