import * as LINKS from './consts'

const ProductCatalog = () => import('@components/Profile/Shop/Product/Catalog')
const ProductQuestions = () => import('@components/Profile/Shop/Product/Questions')
const ProductView = () => import('@components/Profile/Shop/Product/View')

export const routes = [
  {
    path: LINKS.PROFILE_PRODUCTS,
    component: ProductCatalog,
    name: 'products',
    meta: { permissions: ['products'] },
  },
  {
    path: LINKS.PROFILE_PRODUCT_COMMON,
    component: ProductView,
    name: 'productView',
    props: true,
    meta: { permissions: ['products'] },
  },
  {
    path: LINKS.PROFILE_PRODUCT_CREATE,
    component: ProductView,
    name: 'productCreate',
    meta: { permissions: ['products'] },
  },
  {
    path: LINKS.PROFILE_PRODUCT_QUESTIONS,
    component: ProductQuestions,
    name: 'productQuestions',
    props: true,
    meta: { permissions: ['products'] },
  },
]
