import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'comparison'

const COMPARISON_CREATE_LINK = prefixAPI('/create/', MODEL_PREFIX)
const COMPARISON_DELETE_LINK = prefixAPI('/delete/', MODEL_PREFIX)
// TODO relocate to another service
const SESSION_RETRIEVE_LINK = prefixAPI('/retrieve/', MODEL_PREFIX)

export const comparisonCreateResource = createResource(COMPARISON_CREATE_LINK, postResource)
export const comparisonDeleteResource = createResource(COMPARISON_DELETE_LINK, postResource)
export const sessionRetrieve = createResource(SESSION_RETRIEVE_LINK)
