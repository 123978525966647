const ShopProducts = () => import('@components/Profile/Shop/Product/Catalog')
const ShopProductsFilter = () => import('@components/Profile/Shop/Product/Filter')
const ProductCare = () => import('@components/Profile/Shop/Product/Care')
const ProductNavigation = () => import('@components/Profile/Shop/Product/Navigation')
const ProductCategories = () => import('@components/Profile/Shop/Product/Categories')
const ProductCommon = () => import('@components/Profile/Shop/Product/Common')
const ProductSpecification = () => import('@components/Profile/Shop/Product/Specifications')

export default function install(Vue) {
  Vue.component('ShopProducts', ShopProducts)
  Vue.component('ShopProductsFilter', ShopProductsFilter)
  Vue.component('ProductCare', ProductCare)
  Vue.component('ProductNavigation', ProductNavigation)
  Vue.component('ProductCategories', ProductCategories)
  Vue.component('ProductCommon', ProductCommon)
  Vue.component('ProductSpecification', ProductSpecification)
}
