<template lang="pug">
  include /mixins.pug
  label(
    v-bem.label='{"bound": true}'
    :class="{'is-active': value }"
    :for='id',
    v-if="inputLabel"
  ) {{ inputLabel }}
    slot
    +b.SPAN.ds-caption.--color_main(
      v-if='isRequired'
    ) &nbsp;*
    +b.SPAN.ds-caption.--size_4xs(v-if='description') &nbsp; ({{ description }})
</template>

<script>
export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  props: {
    labelText: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    description: {},
  },
}
</script>
