import { createI18n } from '@/i18n'
import { createRouter, checkPermissions } from '@/js/router'
import { createStore } from '@/js/store'
import { mapActions } from 'vuex'
import disableHover from '@/js/scripts/disableHover'

export function createApp({ Vue, VueRouter, Vuex, I18n }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, VueRouter })
  const store = createStore({ Vue, Vuex })
  checkPermissions(router)
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        isLoading: true,
        serviceReadMoreChars: 250,
      }
    },
    created() {
      // TODO: call for pages with products
      this.getSessionInfo()
    },
    mounted() {
      this.initServicesReadMore()
      this.checkSearchParams()
      const timeout = 1500
      setTimeout(() => {
        this.isLoading = false
      }, timeout)
      disableHover()
    },
    methods: {
      ...mapActions('catalog', [
        'getSessionInfo',
      ]),
      initServicesReadMore() {
        const lg = 992
        if (window.innerWidth >= lg) {
          this.serviceReadMoreChars = 950
        }
      },
      checkSearchParams() {
        const searchUrl = window.location.search
        if (searchUrl.includes('?next=')) {
          const modal = () => import('@components/Modals/Auth')
          this.$modal.show(modal, {}, {
            clickToClose: false,
          })
        }
      },
    },
  })

  return { app, router, store, i18n }
}
