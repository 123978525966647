<template lang="pug">
  include /mixins.pug
  .d-control-input
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :input-label='inputLabel',
      :is-required='required'
      :hint="hint"
      :description="description"
    )
    +b.d-control-input__wrapper(
      :class="{'is-focused': isFocused}"
    )
      component(
        v-if="prependIcon"
        :is="prependComponent"
        :prepend-icon="prependIcon"
        :icon-prepend-func="iconPrependFunc"
      )
      component.d-control-input__element(
        v-if="mask"
        :is="element",
        :value.prop='value',
        v-mask="{mask: mask, placeholder: '_', showMaskOnHover: false}"
        ref='input'
        v-bem.element=''
        v-on="listeners"
        :id='id',
        v-bind='$attrs'
        :type='type'
        v-model='model',
      )
      component.d-control-input__element(
        v-if="!mask"
        :is="element",
        :value.prop='value',
        :class="{'d-control-input__element--icon_prepend': prependIcon, 'd-control-input__element--icon_append': appendIcon || type == 'password'}"
        ref='input'
        v-bem.element=''
        v-on="listeners"
        :id='id',
        v-bind='$attrs'
        :type='type'
        v-model='model',
      )
        template(
          v-slot:[name]="binded"
          v-for="(name, key) in eSlots"
        )
          slot(
            v-bind="binded"
            :name="key"
          )
      component(
        v-if="appendIcon"
        :is="appendComponent"
        :append-icon="appendIcon"
        :icon-append-func="iconAppendFunc"
      )
      +b.d-control-input__icon.--append.icon(
        v-if='type == "password"'
        @click.prevent='changeType()',
        :class='!show ? "icon--type_eyes1" : "icon--type_eyes2"'
      )
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'prependComponent',
    'prependIcon',
    'iconPrependFunc',
    'appendComponent',
    'appendIcon',
    'iconAppendFunc',
    'labelComponent',
    'id',
    'value',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'mask',
    'hint',
    'description',
  ],
  block: 'd-control-input',
  watch: {
    value: {
      handler(nval) {
        this.input = nval
      },
    },
  },
  data() {
    return {
      input: '',
      show: false,
      isFocused: false,
    }
  },
  computed: {
    eSlots() {
      // TODO ADD SIMPLE SLOTS, NOT SCOPED
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        focus: () => { this.isFocused = true },
        ...this.$listeners,
        input: $event => { this.$emit('input', $event ? $event.id ? $event : ($event.type ? $event.target[this.getKey()] : $event) : $event )},
        blur: () => { this.isFocused = false, this.$emit('blur') },
      }
    },
    model: {
      get() {
        return this.input = this.value
      },
      set(e) {
        this.input = e
      }
    }
  },
  methods: {
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    }
  }
}
</script>
