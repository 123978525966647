import * as LINKS from './consts'

const CollectionCatalog = () => import('@components/Profile/Shop/Collection/Catalog')
const CollectionView = () => import('@components/Profile/Shop/Collection/View')

export const routes = [
  {
    path: LINKS.PROFILE_COLLECTIONS,
    component: CollectionCatalog,
    name: 'productCollections',
    meta: { permissions: ['productCollections'] },
  },
  {
    path: LINKS.PROFILE_COLLECTION_COMMON,
    component: CollectionView,
    name: 'productCollectionView',
    props: true,
    meta: { permissions: ['productCollections'] },
  },
  {
    path: LINKS.PROFILE_COLLECTION_CREATE,
    component: CollectionView,
    name: 'productCollectionCreate',
    meta: { permissions: ['productCollections'] },
  },
]
