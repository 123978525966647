const UiTabs = () => import('@components/Common/UiTabs')
const UiDropdown = () => import('@components/Common/UiDropdown')
const UiUploader = () => import('@components/Common/UiUploader')
const Renderer = () => import('@components/Common/Renderer')
const UiSlider = () => import('@components/Common/UiSlider')
const UiSlickSlider = () => import('@components/Common/UiSlickSlider')
const UiRecaptcha = () => import('@components/Common/UiRecaptcha')
const UiMap = () => import('@components/Common/UiMap')
const MasonryGallery = () => import('@components/Common/MasonryGallery')
const GalleryVideoOpener = () => import('@components/Common/GalleryVideoOpener')
const UiPagination = () => import('@components/Common/UiPagination')
const ViewerTrigger = () => import('@components/Common/ViewerTrigger')
const ThumbSlider = () => import('@components/Common/ThumbSlider')
const ModalTrigger = () => import('@components/Common/ModalTrigger')
const ShareButton = () => import('@components/Common/ShareButton')
const UiCountdown = () => import('@components/Common/UiCountdown')
const UiCropper = () => import('@components/Common/UiCropper')
const CropperUploader = () => import('@components/Common/CropperUploader')
const UiCounter = () => import('@components/Common/UiCounter')
const UiSearch = () => import('@components/Common/UiSearch')
const UiSwitch = () => import('@components/Common/UiSwitch')
const ClampField = () => import('@components/Common/ClampField')
const LikeButton = () => import('@components/Common/LikeButton')
const UiEditor = () => import('@components/Common/UiEditor')

export default function install(Vue) {
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiDropdown', UiDropdown)
  Vue.component('UiUploader', UiUploader)
  Vue.component('Renderer', Renderer)
  Vue.component('UiSlider', UiSlider)
  Vue.component('UiSlickSlider', UiSlickSlider)
  Vue.component('UiRecaptcha', UiRecaptcha)
  Vue.component('UiMap', UiMap)
  Vue.component('MasonryGallery', MasonryGallery)
  Vue.component('GalleryVideoOpener', GalleryVideoOpener)
  Vue.component('UiPagination', UiPagination)
  Vue.component('ViewerTrigger', ViewerTrigger)
  Vue.component('ThumbSlider', ThumbSlider)
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('ShareButton', ShareButton)
  Vue.component('UiCountdown', UiCountdown)
  Vue.component('UiCropper', UiCropper)
  Vue.component('CropperUploader', CropperUploader)
  Vue.component('UiCounter', UiCounter)
  Vue.component('UiSearch', UiSearch)
  Vue.component('UiSwitch', UiSwitch)
  Vue.component('ClampField', ClampField)
  Vue.component('LikeButton', LikeButton)
  Vue.component('UiEditor', UiEditor)
}
