import Vue from 'vue'

Vue.directive('accordion', {
  bind: node => {
    const timeout = 200
    const triggers = node.querySelectorAll('.js-accordion-trigger')
    triggers.forEach(el => {
      el.addEventListener('click', () => {
        triggers.forEach(elem => {
          if (elem !== el) {
            elem.closest('.js-accordion-item').classList.remove('is-active')
          }
        })
        el.closest('.js-accordion-item').classList.toggle('is-active')
        setTimeout(() => {
          const topPos = el.getBoundingClientRect().top + window.scrollY
          window.scrollTo({
            top: topPos,
            behavior: 'smooth',
          })
        }, timeout)
      })
    })
  },
})
