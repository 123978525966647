import {
  profileRetrieve,
  profileShopRetrieve,
  profileSidebarCounters,
} from '@services/profile.service'

export const profile = {
  namespaced: true,

  state: {
    profile: null,
    sidebarCounters: {},
  },

  actions: {
    async getSidebarCounters({ commit }) {
      const { data: { item } } = await profileSidebarCounters.execute()

      commit('CHANGE_SIDEBAR_COUNTERS', item)
    },

    async getProfileInfo({ commit }) {
      const methods = {
        designer: profileRetrieve,
        'shop-owner': profileShopRetrieve,
        'shop-manager': profileRetrieve,
      }

      const { role } = JSON.parse(window.userRole)
      const { data: { item } } = await methods[role].execute()

      commit('CHANGE_PROFILE', item)
    },
  },

  mutations: {
    CHANGE_PROFILE(state, result) {
      state.profile = result
    },

    CHANGE_SIDEBAR_COUNTERS(state, result) {
      state.sidebarCounters = result
    },
  },
}
