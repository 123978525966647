<template lang="pug">
  include /mixins
  +b.d-control-input__icon.--append.icon(
    @click.prevent='iconAppendFunc',
    :class="appendIcon"
  )
</template>
<script>
export default {
  name: 'AppendComponent',
  props: {
    appendIcon: {},
    iconAppendFunc: {},
  },
}
</script>
