import {
  createResource,
  postResource,
  patchResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'profile'

const PROFILE_RETRIEVE_LINK = prefixAPI('/retrieve-update/', MODEL_PREFIX)
const PROFILE_PASSWORD_CHANGE_LINK = prefixAPI('/password/change/', MODEL_PREFIX)
const PROFILE_EMAIL_CHANGE_LINK = prefixAPI('/email/change/', MODEL_PREFIX)

export const profileRetrieve = createResource(PROFILE_RETRIEVE_LINK)
export const profileUpdate = createResource(PROFILE_RETRIEVE_LINK, patchResource)
export const profileEmailChange = createResource(PROFILE_EMAIL_CHANGE_LINK, postResource)
export const passwordChange = createResource(PROFILE_PASSWORD_CHANGE_LINK, postResource)

const PROFILE_SHOP_RETRIEVE_LINK = prefixAPI('/shop/retrieve-update/', MODEL_PREFIX)

export const profileShopRetrieve = createResource(PROFILE_SHOP_RETRIEVE_LINK)
export const profileShopUpdate = createResource(PROFILE_SHOP_RETRIEVE_LINK, patchResource)

const PROFILE_REQUEST_LIST_LINK = prefixAPI('/contact/request/list/{page}', MODEL_PREFIX)
const PROFILE_REQUEST_UPDATE_LINK = prefixAPI('/contact/request/{id}/update/', MODEL_PREFIX)
const PROFILE_SERVICE_REQUEST_LINK = prefixAPI('/service/request/list/{;filters*}{page}', MODEL_PREFIX)
const PROFILE_SERVICE_REQUEST_UPDATE_LINK = prefixAPI('/service/request/{id}/update/', MODEL_PREFIX)

export const profileRequestList = createResource(PROFILE_REQUEST_LIST_LINK)
export const profileRequestUpdate = createResource(PROFILE_REQUEST_UPDATE_LINK, patchResource)
export const profileServiceRequest = createResource(PROFILE_SERVICE_REQUEST_LINK)
export const profileServiceRequestUpdate = createResource(PROFILE_SERVICE_REQUEST_UPDATE_LINK, patchResource)

const PROFILE_SIDEBAR_COUNTERS_LINK = prefixAPI('/sidebar/counters/retrieve/', MODEL_PREFIX)
export const profileSidebarCounters = createResource(PROFILE_SIDEBAR_COUNTERS_LINK)
