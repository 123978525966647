<template lang="pug">
  include /mixins
  +b.d-control-input__icon.--prepend.icon(
    @click.prevent='iconPrependFunc',
    :class="prependIcon"
  )
</template>
<script>
export default {
  name: 'PrependComponent',
  props: {
    prependIcon: {},
    iconPrependFunc: {},
  },
}
</script>
