<template lang="pug">
  span(
    v-bem=''
    :class='classes'
  )
    slot
</template>
<script>
export default {
  name: 'ErrorComponent',
  block: 'control-error',
  props: {
    classes: {},
  },
}
</script>
