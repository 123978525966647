const LanguageSwitch = () => import('@components/Navigation/LanguageSwitch')
const SidebarTrigger = () => import('@components/Navigation/SidebarTrigger')
const CategoryTrigger = () => import('@components/Navigation/CategoryTrigger')
const Categories = () => import('@components/Navigation/Categories')

export default function install(Vue) {
  Vue.component('LanguageSwitch', LanguageSwitch)
  Vue.component('SidebarTrigger', SidebarTrigger)
  Vue.component('CategoryTrigger', CategoryTrigger)
  Vue.component('Categories', Categories)
}
