import * as LINKS from './consts'

const ShopImport = () => import('@components/Profile/Shop/Import/View')

export const routes = [
  {
    path: LINKS.PROFILE_SHOP_IMPORT,
    component: ShopImport,
    name: 'importSettings',
    meta: { permissions: ['importSettings'] },
  },
]
