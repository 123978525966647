const ShopSettings = () => import('@components/Profile/Shop/Settings/Settings')
const ShopCommonInfo = () => import('@components/Profile/Shop/Settings/CommonInfo')
const ShopFeatures = () => import('@components/Profile/Shop/Settings/Features')
const ShopPhones = () => import('@components/Profile/Shop/Settings/Phones')
const ShopGallery = () => import('@components/Profile/Shop/Settings/Gallery')

export default function install(Vue) {
  Vue.component('ShopSettings', ShopSettings)
  Vue.component('ShopCommonInfo', ShopCommonInfo)
  Vue.component('ShopFeatures', ShopFeatures)
  Vue.component('ShopPhones', ShopPhones)
  Vue.component('ShopGallery', ShopGallery)
}
