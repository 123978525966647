const DesignerSettings = () => import('@components/Profile/Designer/Settings')
const DesignerServices = () => import('@components/Profile/Designer/DesignerServices')
const DesignerInfoBlocks = () => import('@components/Profile/Designer/DesignerInfoBlocks')
const DesignerCounters = () => import('@components/Profile/Designer/DesignerCounters')
const DesignerFeatures = () => import('@components/Profile/Designer/DesignerFeatures')

export default function install(Vue) {
  Vue.component('DesignerSettings', DesignerSettings)
  Vue.component('DesignerServices', DesignerServices)
  Vue.component('DesignerInfoBlocks', DesignerInfoBlocks)
  Vue.component('DesignerCounters', DesignerCounters)
  Vue.component('DesignerFeatures', DesignerFeatures)
}
